import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { margin, fluidRange } from 'polished';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import Grid from '../components/grid/grid';
import GridCell from '../components/grid/gridCell';
import PanoramaDefault from '../components/parts/panoramas/panoramaDefault';
import CardHorizontal from '../components/parts/cards/cardHorizontal';
import CardPeople from '../components/parts/cards/cardPeople';
import { TitleText, Quote, ExternalButton, InternalButton, Figure, Video, Carousel } from '../components/slices';
import breakpoints from '../styles/settings/breakpoints';

const Single = ({ data, pageContext }) => {
    const prismicData = data.prismic.allSingles.edges[0]?.node;

    if (!prismicData) {
        return false;
    }

    const panoramaImage = prismicData.panorama_usecolor
        ? null
        : { src: prismicData.panorama_imageSharp.childImageSharp.fluid, alt: prismicData.panorama_image.alt };

    const panorama = {
        title: prismicData.panorama_title[0].text,
        text: prismicData.panorama_text ? prismicData.panorama_text[0].text : '',
        useColor: prismicData.panorama_usecolor,
        image: panoramaImage
    };

    const flexLayouts = () => {
        if (prismicData.layouts) {
            return prismicData.layouts.map((slice, key) => {
                const newKey = `${slice.type}-${key}`;

                switch (slice.type) {
                    case 'title_text':
                        return <TitleText slice={slice.primary} key={newKey} />;
                    case 'quote':
                        return <Quote slice={slice.primary} key={newKey} />;
                    case 'external_button':
                        return <ExternalButton slice={slice.primary} key={newKey} />;
                    case 'internal_button':
                        return <InternalButton slice={slice.primary} key={newKey} />;
                    case 'figure':
                        return <Figure slice={slice.primary} key={newKey} />;
                    case 'video':
                        return <Video slice={slice.primary} key={newKey} />;
                    case 'carousel':
                        return <Carousel slice={slice.fields} key={newKey} />;
                    default:
                        return;
                }
            });
        }
    };

    const employeeCards = () => {
        const employees = data.prismic.allEmployees.edges;
        let filteredEmployees = [];

        employees.map(employee => {
            const groups = employee.node.groups;

            if(groups) {
                groups.filter(item => {
                    const groupId = item.group?._meta.uid;
    
                    if (groupId === pageContext.uid) {
                        filteredEmployees.push(employee);
                    }
                });
            }
        });

        if (filteredEmployees.length === 0) return false;

        const employeesArray = filteredEmployees.map((item, index) => {
            const employee = item.node;

            return (
                <GridCell cellSizes={{ small: '100%', medium: '50%', large: `${(1 / 3) * 100}%` }} key={index}>
                    <CardPeople
                        content={{
                            image: employee.avatarSharp.childImageSharp.fixed,
                            name: employee.name,
                            jobTitle: employee.jobtitle,
                            groups: employee.groups,
                            quote: employee.quote
                        }}
                    >
                        {index}
                    </CardPeople>
                </GridCell>
            );
        });

        return (
            <SRelated>
                <Contain>
                    <SRelatedHeading>Het team</SRelatedHeading>
                    <SCards>
                        <Grid gutter="normal">{employeesArray}</Grid>
                    </SCards>
                </Contain>
            </SRelated>
        );
    };

    const relatedItems = [];
    const { related, related_title } = prismicData;

    const relatedCards = () => {
        if (related) {
            const relatedTitle = related_title ? related_title[0].text : 'Gerelateerd';
            for (const [index, { card }] of related.entries()) {
                if (!card) {
                    return false;
                }

                const metaData = card._meta;
                const linkTags = metaData?.tags;
                const linkTag = linkTags && linkTags.length > 0 ? linkTags[0] : '';
                const linkUid = metaData ? metaData.uid : '';
                const link = `${linkTag}/${linkUid}`;

                relatedItems.push(
                    <CardHorizontal
                        content={{
                            image: card.archive_imageSharp.childImageSharp.fluid,
                            title: card.title[0].text,
                            description: card.description,
                            link: link
                        }}
                        key={index}
                    >
                        {card.title}
                    </CardHorizontal>
                );
            }

            return (
                <SRelated>
                    <Contain>
                        <SRelatedHeading>{relatedTitle}</SRelatedHeading>
                        <SCards>{relatedItems}</SCards>
                    </Contain>
                </SRelated>
            );
        }
        return false;
    };

    return (
        <Layout>
            <SEO title={panorama.title} />
            <PanoramaDefault
                background={panorama.useColor}
                alignment="center"
                content={{
                    image: panorama.image,
                    title: panorama.title,
                    text: panorama.text
                }}
            />
            <Contain>
                <SCopy>{flexLayouts()}</SCopy>
            </Contain>

            {employeeCards()}

            {relatedCards()}
        </Layout>
    );
};

const SCopy = styled.div`
    padding-top: 40px;
    padding-bottom: 60px;

    > * {
        ${margin(null, 'auto', null, 'auto')}
        max-width: 640px;
    }

    ${breakpoints.large} {
        padding-top: 60px;
        padding-bottom: 80px;

        ${'' /* > div > div {
            padding-top: 60px;
            padding-bottom: 80px;
        } */}
    }
`;

const SCards = styled.div`
    > div > div {
        margin-top: 20px;
    }

    ${breakpoints.large} {
        > div > div {
            margin-top: 40px;
        }
    }
`;

const SRelated = styled.div`
    padding-top: 40px;
    padding-bottom: 80px;
    border-top: 1px solid var(--color-gray-lighter);

    ${breakpoints.large} {
        padding-top: 60px;
        padding-bottom: 100px;
    }
`;

const SRelatedHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '25px'
        },
        '320px',
        '1440px'
    )}
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
`;

export const pageQuery = graphql`
    query SingleQuery($uid: String) {
        prismic {
            allSingles(uid: $uid) {
                edges {
                    node {
                        _meta {
                            uid
                        }
                        panorama_image
                        panorama_imageSharp {
                            childImageSharp {
                                fluid(maxWidth: 2560, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        panorama_title
                        panorama_text
                        panorama_usecolor
                        layouts {
                            ... on PRISMIC_SingleLayoutsTitle_text {
                                type
                                primary {
                                    title_textTitle
                                    title_textText
                                }
                            }
                            ... on PRISMIC_SingleLayoutsQuote {
                                type
                                primary {
                                    quote_text
                                    quote_cite
                                }
                            }
                            ... on PRISMIC_SingleLayoutsExternal_button {
                                type
                                primary {
                                    button_label
                                    button_url {
                                        ... on PRISMIC__ExternalLink {
                                            url
                                        }
                                        ... on PRISMIC__FileLink {
                                            url
                                        }
                                        ... on PRISMIC__ImageLink {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_SingleLayoutsInternal_button {
                                type
                                primary {
                                    button_label
                                    button_url {
                                        ... on PRISMIC_Home {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Single {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Team {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Archive {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_SingleLayoutsFigure {
                                type
                                primary {
                                    caption
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 1280, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_SingleLayoutsVideo {
                                type
                                primary {
                                    url
                                }
                            }
                            ... on PRISMIC_SingleLayoutsCarousel {
                                type
                                fields {
                                    image
                                }
                            }
                        }
                        related_title
                        related {
                            card {
                                ... on PRISMIC_Single {
                                    title
                                    description
                                    archive_image
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 2560, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allEmployees {
                edges {
                    node {
                        name
                        jobtitle
                        quote
                        avatar
                        avatarSharp {
                            childImageSharp {
                                fixed(width: 60, quality: 90) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                        groups {
                            group {
                                ... on PRISMIC_Single {
                                    title
                                    _meta {
                                        uid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Single;

Single.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired
};
